import { FC } from "react";
import { InsuranceInformationType, InsuranceInformationProps } from "./type";
// import { useNavigate } from "react-router-dom";

const InsuranceInformation: FC<InsuranceInformationProps> = ({ insuranceInformation, insuranceNumber, formatPhoneNumber }): JSX.Element => {

  const precertCheck = (item: InsuranceInformationType) => {

    if (item.precert_status !== 'N/A' ) {

        return (
          <p className="m-1 rounded-0">
            {item.precert_status.charAt(0).toUpperCase() + item.precert_status.slice(1)}
          </p>
        )
      }  else {
      return `${item.precert_status}`
    }
  }
  return (
    <div className="card m-2 my-4">
      <div className="card-header">
        <p className="p-2 mb-0 fs-5 text-center">
          Insurance # {insuranceNumber}: {insuranceInformation ? insuranceInformation.insurance_priority : "N/A"}
        </p>
      </div>

      <div className="card-body">
        <div className="row">

          <div className="col-sm-6 col-12">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Relation to Patient:</p>
              </div>
              <div className="col-6">
                <p>
                  {insuranceInformation ? insuranceInformation.relation_to_patient : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-12">
            {insuranceInformation && insuranceInformation.relation_to_patient !== 'Self' && (
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Relation First Name:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.relation_first_name : "N/A"}
                    </p>
                  </div>
                </div>
            )}
          </div>

          {insuranceInformation && insuranceInformation.relation_to_patient !== 'Self' && (
              <>
                <div className="col-sm-6 col-12">
                  <div className="row">
                    <div className="col-6 text-end px-3">
                      <p>Relation Middle Name:</p>
                    </div>
                    <div className="col-6">
                      <p>
                        {insuranceInformation ? insuranceInformation.relation_middle_name : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="row">
                    <div className="col-6 text-end px-3">
                      <p>Relation Last Name:</p>
                    </div>
                    <div className="col-6">
                      <p>
                        {insuranceInformation ? insuranceInformation.relation_last_name : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
          )}

          <div className="col-sm-6 col-12">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Plan Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {insuranceInformation ? insuranceInformation.plan_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-12">
            <div className="row">
              <div className="col-6 text-end px-3">
                <label>Plan Type:</label>
              </div>
              <div className="col-6">
                <p>
                  {insuranceInformation ? insuranceInformation.plan_type : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Medical Insurance */}
        <div className="card m-2 my-4">
          <div className="card-header">
            <h6 className="text-black p-2 mb-0">Medical Insurance</h6>
          </div>

          <div className="card-body">
            <div className="row">

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Member ID:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.member_id : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-4">
                    <p>Group:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.group : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Insurance Phone:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation && insuranceInformation.insurance_phone ? formatPhoneNumber(insuranceInformation.insurance_phone) : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row align-items-center">
                  <div className="col-6 text-end px-3">
                    <p className="mb-0">Precert:</p>
                  </div>
                  <div className="col-6">
                    {precertCheck(insuranceInformation)}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Pharmacy Insurance */}
        <div className="card mx-2 my-2">
          <div className="card-header">
            <h6 className="text-black p-2 mb-0">Pharmacy Insurance</h6>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Rx Member ID:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.rx_member_id : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Rx Member Group:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.rx_group : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>BIN:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.bin : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>PCN:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.pcn : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Prior Authorization:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.prior_authorization : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceInformation;
