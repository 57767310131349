import { FC } from "react"
import { StatisticsObjectType } from "./types";
import CustomButtonRight from "../customButtonRight";
import CustomLineChart from "../customLineChart";

const CaseVolumeHorizontal: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {

  return (
    <div className="row">
        <div className="col-12 col-sm-12 col-md-9 mb-3">
            {<CustomLineChart title={"Case Volume"} LineChartObject={StatisticsObjectType.case_volume}/>}
        </div>
        <div className="col-12 col-sm-12 col-md-3">
            {<CustomButtonRight StatisticsObjectType={StatisticsObjectType}/>}
        </div>
    </div>
  )
}
export default CaseVolumeHorizontal;
