
import { FC, useContext, useEffect, useState } from "react"
import { DownloadReportsResponseTypeApi, StatsReportProps } from "./types";
// import { getValues } from "src/constants/highCharts";
import useFetch from "src/hooks/useFetch";
import { DownloadZipErrorType } from "src/pages/dashboard/types";
import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastContext } from "src/context/toastContext";
import { downloadExcelFile } from "src/helpers/downloadFile";

const StatsReports: FC<StatsReportProps> = ({ getValues }): JSX.Element => {

  const [enabledButton, setEnabledButton] = useState<boolean>(false);
  const { destroySession } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const {
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

  useEffect(() => {
    hideLoader();
    if (downloadReportsError) {
      hideLoader();
      if (typeof downloadReportsError === 'string') {
        showToast([downloadReportsError], ToastTypes.ERROR);
        setEnabledButton(false);
      } else {
        const { code, error } = downloadReportsError as unknown as DownloadZipErrorType;
        setEnabledButton(false);
        if (code === 401) {
          destroySession();
          showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
          navigate(`/users/sign_in`);
        }
        showToast([error], ToastTypes.ERROR);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsError]);

  useEffect(() => {
    if (downloadReportsData) {
      const { response, report_name } = downloadReportsData;
      downloadExcelFile(response, `${report_name}.xlsx`);
      hideLoader();
      setEnabledButton(false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData]);


  const DownloadReport = (reportName: string) => {
   if(getValues){
    let params = {
      'start_date':getValues().start_date,
      'end_date' : getValues().end_date,
      'report_name': reportName
    }
    if (downloadReportsData || downloadReportsError) clearRequestFileData();
    setLoader();
    setEnabledButton(true)
    downloadRequestForm({}, {}, params);
   }



  }
  return (
    <div className="row">
      <div className="col-6 col-sm-6 col-md-2">
        <button className="btn btn-primary w-100" onClick={() => { DownloadReport("sp_transfers_report") }} disabled={enabledButton}>
            SP Transfers Report
        </button>
      </div>
        <div className="col-6 col-sm-6 col-md-2">
            <button className="btn btn-primary w-100" onClick={() => {
                DownloadReport("full_report")
            }} disabled={enabledButton}>
                Full Report
            </button>
        </div>
    </div>
  );
};

export default StatsReports;
