import { FC, useState } from "react"
import { Link } from "react-router-dom";

import StepperModal from "../stepperModal";

import { CaseDetailPropsType, QueryParamsState } from "./types";
import { objectToQueryString } from "src/helpers";
import { CASE_DETAIL_HEADER } from "src/constants/manufacturer";



const CaseDetail: FC<CaseDetailPropsType> = ({ baseUrl, searchParams, CaseDetailListType }): JSX.Element => {
  const [showingModal, setModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [caseDetails, getCaseDetails] = useState<number>(0);
  const [queryParams, updateQueryParams] = useState<QueryParamsState>({
    direction: "desc",
    sort_by: "case_id",
  });

  const openModal = (caseId: number) => {
    setModal(true);
    getCaseDetails(caseId);
  };

  const changeIdOrder = (tableHeader: string) => {
    const updatedQueryParams: QueryParamsState = {
      sort_by: tableHeader,
      direction: tableHeader === queryParams.sort_by ? (queryParams.direction === "desc" ? "asc" : "desc") : "desc"
    };

    switch (updatedQueryParams.sort_by) {
      case "case_id":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return Number(item1.case_id) - Number(item2.case_id);
          else
            return Number(item2.case_id) - Number(item1.case_id);
        });
        break;

      case "patient_id":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return Number(item1.patient_id) - Number(item2.patient_id);
          else
            return Number(item2.patient_id) - Number(item1.patient_id);
        });
        break;

      case "bi_ct":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return Number(item1.bi_ct) - Number(item2.bi_ct);
          else
            return Number( item2.bi_ct) - Number(item1.bi_ct);
        });
        break;

      case "qty":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return Number(item1.qty || 0) - Number(item2.qty || 0);
          else
            return Number(item2.qty || 0) - Number(item1.qty || 0);
        });
        break;
      case "received":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return (item1.received || "").localeCompare(item2.received || "");
          else
            return (item2.received || "").localeCompare(item1.received || "");
        });
        break;

      case "completed":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return (item1.completed || "").localeCompare(item2.completed || "");
          else
            return (item2.completed || "").localeCompare(item1.completed || "");
        });
        break;
      case "payer":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return (item1.payer || "").localeCompare(item2.payer || "");
          else
            return (item2.payer || "").localeCompare(item1.payer || "");
        });
        break;
      case "case_status":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return (item1.case_status || "").localeCompare(item2.case_status || "");
          else
            return (item2.case_status || "").localeCompare(item1.case_status || "");
        });
        break;
      case "provider.doc_name":
        CaseDetailListType.sort((item1, item2) => {
          if (updatedQueryParams.direction === "asc")
            return item1.provider.doc_name.localeCompare(item2.provider.doc_name);
          else
            return item2.provider.doc_name.localeCompare(item1.provider.doc_name);
        });
        break;
    }

    updateQueryParams(updatedQueryParams);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="heading-title text-center">Case Details</h4>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive overflow-auto" style={{ minHeight: "50px", maxHeight: "350px" }}>

            <table className="table table-sm mb-0">
              <thead className="thead-sticky">
                <tr>
                  {CASE_DETAIL_HEADER.map((tableHeader, index) => (
                    <th
                      key={index}
                      className="text-center"
                      scope="col"
                      onClick={tableHeader.value !== '' ? () => changeIdOrder(tableHeader.value) : undefined}
                    >
                      <span
                        role={tableHeader.value === '' ? "textbox" : "button"}
                        className={
                          tableHeader.value === '' ?
                            'fw-bold fs-6 lh-base' :
                            'btn btn-link link-primary link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 fw-bold'
                        }
                      >
                        {tableHeader.label}
                        {queryParams.sort_by === tableHeader.value ? (
                          <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className="table-group-divider">
                {CaseDetailListType &&
                  CaseDetailListType.map((item, index) => (
                    <tr key={index} className="align-middle">
                      <td className="text-center">
                        <button className="btn btn-primary btn-case rounded-0" onClick={() => openModal(item.case_id)}>
                          {item?.case_id}
                        </button>
                      </td>
                      <td className="text-center">
                        <Link
                          to={{
                            pathname: baseUrl,
                            search: objectToQueryString(baseUrl === "/statistics" ? {
                              global_patient_external_id: item.patient_id,
                              detail: true
                            } : {
                              id: searchParams.id,
                              filter_type: searchParams.filter_type,
                              role: searchParams.role,
                              period: searchParams.period,
                              global_patient_external_id: item.patient_id,
                              detail: true
                            })
                          }}
                          target="_blank"
                          className="link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover"
                        >
                          {item?.patient_id}
                        </Link>
                      </td>
                      <td className="text-center">{item?.bi_ct}</td>
                      <td className="text-center">{item?.qty}</td>
                      <td className='text-center'>{item.received}</td>
                      <td className="text-center">{item?.completed}</td>
                      <td className="text-center">{item?.treatment}</td>
                      <td className="text-center">{item?.payer}</td>
                      <td className="text-center">{item?.outcome}</td>
                      <td className='text-center'>{item.rx_cvge}</td>
                      <td className="text-center">{item?.med_cvge}</td>
                      <td className="text-center">{item?.rx_pa.data}</td>
                      <td className="text-center">{item?.med_pa.data}</td>
                      <td className={`text-center align-middle text-white background-color-${item && item.color_idx ? item.color_idx : 1}`}
                        style={{ minWidth: "200px" }}>
                        {item.case_status}
                      </td>
                      <td className="text-center">{item?.update}</td>
                      <td className="text-center">
                        <Link
                          to={{
                            pathname: baseUrl,
                            search: objectToQueryString(baseUrl === "/statistics" ? {
                              start_date: searchParams.start_date,
                              end_date: searchParams.end_date,
                              search_str: item.provider.doc_name,
                              detail: false
                            } : {
                              id: searchParams.id,
                              filter_type: searchParams.filter_type,
                              role: searchParams.role,
                              period: searchParams.period,
                              start_date: searchParams.start_date,
                              end_date: searchParams.end_date,
                              search_str: item.provider.doc_name,
                              detail: false,
                            })
                          }}
                          target="_blank"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          className="link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover"
                          title={item && item.provider ? `${(item.provider.practice_name ? `Practice: ${item.provider.practice_name}` : '')}${(item.provider.phone ? `\nPhone: ${item.provider.phone}` : '')}${(item.provider.fax ? `\nFax: ${item.provider.fax}` : '')}` : ''}
                        >
                          {item?.provider.doc_name}
                        </Link>
                      </td>

                    </tr>
                  ))
                }
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <div className="col-8">      
        {showingModal ? <StepperModal onClick={() => { setModal(false); getCaseDetails(0) }} /> : null}
      </div>   
     </>
  );
};

export default CaseDetail;
