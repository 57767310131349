import { AppRoute, WithRequired } from "./types";

//:- All Application Pages
import HubLogin from "../pages/login";
import HubForgotPassword from  '../pages/forgotpassword';
import NewEnrollment from "../pages/newEnrollment";
import HubUploadPEF from "../pages/uploadPEF";
import HubResetPassword from "../pages/resetpassword";
import Dashboard from "../pages/dashboard";
import HubAddCase from "../pages/addCase";
import HubCreateUser from "../pages/createUser";
import HubCaseDetail from "src/pages/caseDetail";
import AddProvider from "../pages/addProvider";
import AddUser from "../pages/addUser";
import NewRepVisit from "../pages/newRepVisit";
import BaaAgreement from "../pages/baa";
import manufacturer from "src/pages/manufacturer";
import HubCaseClone from "src/pages/cloneCase";
import Territories from "src/pages/territories";
import TreatmentListing from "src/pages/treatmentListing";
import OrderListing from "src/pages/orderListing";
import Precert from "src/pages/precert";
import HubUpdateUser from "src/pages/updateuser";
import DoctorStatistics from "src/pages/doctorStatistics";

const defaultRoute: WithRequired<WithRequired<Partial<AppRoute>, 'isIndex'>, 'removeHeaderandFooter'> = {
  isIndex: false,
  removeHeaderandFooter: false,
};

export const PublicRoutes: AppRoute[] = [
  { ...defaultRoute, route: '/users/sign_in', component: HubLogin, isIndex: true },
  { ...defaultRoute, route: '/users/forgotpassword', component: HubForgotPassword },
  { ...defaultRoute, route: '/users/password/new', component: HubForgotPassword },
  { ...defaultRoute, route: '/enrollments/new', component: NewEnrollment },
  { ...defaultRoute, route: '/users/uploadPEF', component: HubUploadPEF },
  { ...defaultRoute, route: '/set_new_password', component: HubResetPassword },
  { ...defaultRoute, route: '/pages/baa', component: BaaAgreement },
];

export const AuthenticatedRoutes: AppRoute[] = [
  { ...defaultRoute, route: '/cases', component: Dashboard, isIndex: true },
  { ...defaultRoute, route: '/cases/new', component: HubAddCase },
  { ...defaultRoute, route: '/cases/detail/:caseId', component: HubCaseDetail },
  { ...defaultRoute, route: '/users/createuser', component: HubCreateUser },
  { ...defaultRoute, route: '/users/updateuser', component: HubUpdateUser },
  { ...defaultRoute, route: '/doctors/new', component: AddProvider },
  { ...defaultRoute, route: '/users/new', component: AddUser },
  { ...defaultRoute, route: '/rep_visits/new', component: NewRepVisit },
  { ...defaultRoute, route: '/treatments', component: TreatmentListing },
  { ...defaultRoute, route: '/orders', component: OrderListing },
  { ...defaultRoute, route: '/pages/baa', component: BaaAgreement },
  { ...defaultRoute, route: '/cases/:caseId/clone', component: HubCaseClone },
  { ...defaultRoute, route: '/cases/:caseId/precerts/:precertsId/edit', component: Precert },
  { ...defaultRoute, route: '/cases/:caseId/insurances/:insuranceId/precerts/:precertsId/edit', component: Precert },
];

export const ManufacturerRoutes: AppRoute[] = [
  { ...defaultRoute, route: '/statistics', component: manufacturer, isIndex: true },
  { ...defaultRoute, route: '/territories', component: Territories },
  { ...defaultRoute, route: '/doc_stats', component: DoctorStatistics },
  { ...defaultRoute, route: '/users/createuser', component: HubCreateUser },
  { ...defaultRoute, route: '/users/updateuser', component: HubUpdateUser },
  { ...defaultRoute, route: '/pages/baa', component: BaaAgreement },
];
