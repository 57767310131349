import { FC } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { TriGenLogo } from "../../assets";
import { PublicHeader } from "../../constants/headerLinks";
import { HeaderElementType } from "../../types";



const GuestHeader: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSetNewPasswordPage = location.pathname === '/set_new_password';

  return (
    <nav className="navbar navbar-expand-lg p-2 login-nav">
      <div className="container">
        <Link className="navbar-brand" to="/users/sign_in"><img src={TriGenLogo} alt="Site logo" /></Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#hubHeader">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="hubHeader">
          <ul className="navbar-nav">
            {!isSetNewPasswordPage && PublicHeader.map((headerLink, index) => (
              <li className={`nav-item ${headerLink.actionType === HeaderElementType.DRPODOWN ? 'dropdown' : ''}`} key={index}>
                {headerLink.actionType === HeaderElementType.ROUTE ? (
                  <Link className="nav-link custom-text-purple" aria-current="page" to={headerLink.redirectLink}>{headerLink.title}</Link>
                ) : null}
                {headerLink.actionType === HeaderElementType.BUTTON ? (
                  <button className="btn btn-warning rounded-pill custom-text-purple" onClick={() => { navigate(headerLink.redirectLink) }}>
                    {headerLink.title}
                  </button>
                ) : null}
                {headerLink.actionType === HeaderElementType.LINK ? (
                  <a className="nav-link custom-text-purple" href={headerLink.redirectLink} target="_blank" rel="noreferrer">
                    {headerLink.title}
                  </a>
                ) : null}
                {headerLink.actionType === HeaderElementType.DRPODOWN ? (
                  <>
                    <a className="nav-link dropdown-toggle custom-text-purple" href="." data-bs-toggle="dropdown" aria-expanded="false">
                      {headerLink.title}
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end mobile-dropdown" style={{ top: '53px' }}>
                      {headerLink.dropdown && headerLink.dropdown.map((header, index) => (
                        <li key={index}>
                          <a className="dropdown-item custom-text-purple" href={header.redirectLink} target="_blank" rel="noreferrer">
                            {header.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </li>
            ))}
          </ul>
          {!isSetNewPasswordPage && (
              <Link to="/users/sign_in" className="btn btn-primary ms-0 ms-md-4">Sign In</Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default GuestHeader;
