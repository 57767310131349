import { FC } from "react";
import { ModalType, NewPracticeType } from "./types";
import { createAndDownloadExcelFile } from "src/helpers/downloadFile";


const Modal: FC<ModalType> = ({ title, data, onClick }): JSX.Element => {
  const overFlowBackground = ()=>{
    document.body.style.overflow = 'unset';
    onClick()
  }

  return (
    <div className= {`modal fade loader-index ${title && title.length > 0 ? 'show show-modal' : ''}`} id="typeModal" aria-labelledby="typeModalLabel" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header pt-2 pb-2">
            <h4 className="modal-title" id="typeModalLabel">{title}</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={overFlowBackground}></button>
          </div>

          <div className="modal-body pt-0">
            {title === "New Practices" ?
              <table className="table table-striped table-hover table-responsive position-relative m-0">
                <thead className="sticky-top">
                  <tr>
                    <th scope="col" className="fs-6">Sr#</th>
                    <th scope="col" className="fs-6" style={{minWidth:150}}>Name</th>
                    <th scope="col" className="text-center fs-6">NPI</th>
                    <th scope="col" className="text-center fs-6">State</th>
                    <th scope="col" className="text-end fs-6 col-3">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? data.map((item, index) =>
                    <tr key={index}>
                      <td className="text-17">{index+1}</td>
                      <td className="text-17 text-wrap text-break">{item.name || ""}</td>
                      <td className="text-center text-17">{item.npi || ""}</td>
                      <td className="text-center text-17">{item.state || ""}</td>
                      <td className="text-end text-17">{item && item.phone ? item.phone : ""}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              :
              <table className="table table-hover table-striped table-responsive m-0">
                <thead className="sticky-top">
                <tr>
                  <th className="fs-6">Sr#</th>
                  <th scope="col" className="fs-6" style={{minWidth: 150}}>Name</th>
                  <th className="fs-6">Practice</th>
                  <th className="text-center fs-6">State</th>
                  <th className="text-end fs-6">Phone</th>
                </tr>
                </thead>
                <tbody>
                {data && data.length > 0 ? data.map((item, index) =>
                    <tr key={index}>
                      <td className="text-17">{index+1}</td>
                      <td className="text-17 text-wrap text-break">{item.name || ""} </td>
                      <td className="text-17">{(item as NewPracticeType).practice || ""} </td>
                      <td className="text-center text-17">{item.state || ""}</td>
                      <td className="text-normal text-end text-17 col-2">{item && item.phone ? item.phone : ""}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            }
          </div>

          <div className="modal-footer justify-content-center p-1">
            <button type="button" className="btn btn-primary"
              onClick={() => createAndDownloadExcelFile(data, title === "New Practices")}
            >
              <i className="bi bi-file-earmark-spreadsheet me-2"></i>
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
