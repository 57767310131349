// import { HeaderElement, HeaderElementType } from "../types";

import {HeaderElement, HeaderElementType } from "src/types";

const resourcesHeader = {
  title: 'Resources',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'Avanos Direct Purchase Form',
      redirectLink: '/assets/HarmoKnee-Direct-Purchase-Form.pdf',
    },
    {
      title: 'Avanos Enrollment Form',
      redirectLink: '/assets/HarmoKnee-Enrollment-Form.pdf',
    },
    {
      title: 'Avanos Patient Assistance Form',
      redirectLink: '/assets/HarmoKnee-PAP-Form.pdf',
    },
    {
      title: 'Avanos Benefit Investigation Results Form',
      redirectLink: '/assets/HarmoKnee-Benefit-Investigation-Results-Form.pdf',
    },
    {
      title: 'Billing and Coding Guide',
      redirectLink: '/assets/Billing-and-Coding-Guide.pdf',
    },
    {
      title: 'Direct Purchase Program Overview',
      redirectLink: '/assets/Direct-Purchase-Program-Overview.pdf',
    },
    
    {
      title: 'GenVisc Brochure',
      redirectLink: '/assets/GenVisc-Brochure.pdf',
    },
    {
      title: 'HarmoKnee Support Services',
      redirectLink: '/assets/HarmoKnee-Support-Services.pdf',
    },
    {
      title: 'TriVisc Brochure',
      redirectLink: '/assets/TriVisc-Brochure.pdf',
    },
    {
      title: 'GenVisc IFU',
      redirectLink: '/assets/Genvisc850-IFU.pdf',
    },
    {
      title: 'TriVisc IFU',
      redirectLink: '/assets/Trivisc-IFU.pdf',
    },
    {
      title: 'Portal Tutorial',
      redirectLink: '/assets/portal_overview.mp4',
    },
  ]
};

const publicResourcesHeader = {
  title: 'Resources',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'Avanos Direct Purchase Form',
      redirectLink: '/assets/HarmoKnee-Direct-Purchase-Form.pdf',
    },
    {
      title: 'Avanos Enrollment Form',
      redirectLink: '/assets/HarmoKnee-Enrollment-Form.pdf',
    },
    {
      title: 'Avanos Patient Assistance Form',
      redirectLink: '/assets/HarmoKnee-PAP-Form.pdf',
    },
    // {
    //   title: 'Avanos Benefit Investigation Results Form',
    //   redirectLink: '/assets/HarmoKnee-Benefit-Investigation-Results-Form.pdf',
    // },
    {
      title: 'Billing and Coding Guide',
      redirectLink: '/assets/Billing-and-Coding-Guide.pdf',
    },
    {
      title: 'Direct Purchase Program Overview',
      redirectLink: '/assets/Direct-Purchase-Program-Overview.pdf',
    },
    
    {
      title: 'GenVisc Brochure',
      redirectLink: '/assets/GenVisc-Brochure.pdf',
    },
    {
      title: 'HarmoKnee Support Services',
      redirectLink: '/assets/HarmoKnee-Support-Services.pdf',
    },
    {
      title: 'TriVisc Brochure',
      redirectLink: '/assets/TriVisc-Brochure.pdf',
    },
    {
      title: 'GenVisc IFU',
      redirectLink: '/assets/Genvisc850-IFU.pdf',
    },
    {
      title: 'TriVisc IFU',
      redirectLink: '/assets/Trivisc-IFU.pdf',
    },
    // {
    //   title: 'Portal Tutorial',
    //   redirectLink: 'https://avanos.cmcopilot.com/assets/portal_overview.mp4',
    // },
  ]
};

// export const PublicHeader: HeaderElement[] = [
//   {
//     title: 'Welcome',
//     redirectLink: '/users/sign_in',
//     actionType: HeaderElementType.ROUTE,
//   },
//   {
//     title: 'Enrollment',
//     redirectLink: '/enrollments/new',
//     actionType: HeaderElementType.ROUTE,
//   },
//   // {
//   //   title: 'Patient Enrollment Form (PEF)',
//   //   redirectLink: '/assets/completed-request.pdf',
//   //   actionType: HeaderElementType.LINK,
//   // },
//   // {
//   //   title: 'Upload PEF',
//   //   redirectLink: '/users/uploadPEF',
//   //   actionType: HeaderElementType.BUTTON,
//   // },
// ];

// export const AuthenticatedHeader: HeaderElement[] = [
//   {
//     title: 'Cases',
//     redirectLink: '/cases',
//     actionType: HeaderElementType.ROUTE,
//   },
//   {
//     title: 'Submit a Request',
//     redirectLink: '/cases/new',
//     actionType: HeaderElementType.BUTTON,
//   },
//   // {
//   //   title: 'Orders',
//   //   redirectLink: '/orders',
//   //   actionType: HeaderElementType.ROUTE,
//   // },
//   // {
//   //   title: 'Treatments',
//   //   redirectLink: '/treatments',
//   //   actionType: HeaderElementType.ROUTE,
//   // },
// ];
// export const AuthenticatedManufacturerHeader: HeaderElement[] = [
//   {
//     title: 'National',
//     redirectLink: '/statistics',
//     actionType: HeaderElementType.ROUTE,
//   }
// ];import { HeaderElement, HeaderElementType } from "../types";

export const PublicHeader: HeaderElement[] = [
  {
    title: 'Practice Registration',
    redirectLink: '/enrollments/new',
    actionType: HeaderElementType.ROUTE,
  },
  // {
  //   title: 'Practice Registration',
  //   redirectLink: '/assets/Avanos-Enrollment-Form5.16.24.pdf',
  //   actionType: HeaderElementType.LINK,
  // },

  publicResourcesHeader
  // {
  //   title: 'Patient Savings',
  //   redirectLink: '',
  //   actionType: HeaderElementType.DRPODOWN,
  //   dropdown: [
  //     {
  //       title: 'Benefit Request and Prescription Form',
  //       redirectLink: '/assets/completed-request.pdf',
  //     },
  //     {
  //       title: 'Access Tool Kit',
  //       redirectLink: '/assets/QZA-02-24-0005 v1 Access Kit - D v27.pdf.pdf',
  //     },
  //     {
  //       title: 'Reimbursement Resources',
  //       redirectLink: 'https://qutenzahcp.com/access-and-savings/reimbursement-resources/',
  //     },
  //   ]
  // },
];

export const AuthenticatedManufactureHeader: HeaderElement[] = [
  
  {
    title: 'Statistics',
    redirectLink: '/statistics',
    actionType: HeaderElementType.ROUTE,
  },
  resourcesHeader,
];
export const AuthenticatedHCPHeader: HeaderElement[] = [
  
  // {
  //   title: 'Action Dashboard',
  //   redirectLink: '/todos',
  //   actionType: HeaderElementType.ROUTE,
  // },
  // {
  //   title: 'Patients',
  //   redirectLink: '/patient_dashboards',
  //   actionType: HeaderElementType.ROUTE,
  // },
  {
    title: 'Cases',
    redirectLink: '/cases',
    actionType: HeaderElementType.ROUTE,
  },
  {
    title: 'Submit Request',
    redirectLink: '/cases/new',
    actionType: HeaderElementType.ROUTE,
  },
  resourcesHeader,
  // {
  //   title: 'QutenzaHCP.com',
  //   redirectLink: 'https://www.qutenzahcp.com/',
  //   actionType: HeaderElementType.LINK,
  // }
];


