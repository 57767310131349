import { FC } from "react";
import { InsuranceAttributesProps } from "src/pages/addCase/types";
interface InsuranceProps {
  id: number;
  insurance: InsuranceAttributesProps;
}
const ShowInsuranceInformation: FC<InsuranceProps> = ({ id, insurance }): JSX.Element => {
  return (
    <div className="card my-3 px-0">
      <div className="card-header">
        <h4 className="m-0">
          Insurance # {id}: {insurance && insurance.insurance_name ? insurance.insurance_name : 'N/A'}
        </h4>
      </div>

      <div className="card-body">

        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Relation:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.relation_to_patient ? insurance.relation_to_patient : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>

        {insurance.relation_to_patient !== "self" ? (
          <>
            <div className="row p-2">
              <div className="col-lg-6 col-md-6 col-12 mb-3 mb-sm-0 mb-lg-0">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label">Relative First Name:</label>
                  </div>
                  <div className="col-8">
                    <p>{insurance && insurance.relation_firstname ? insurance.relation_firstname : 'N/A'}</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Relative Middle Name:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.relation_middlename ? insurance.relation_middlename : 'N/A'}</p>
              </div>
            </div>
          </div>
            </div>

            <div className="row p-2">
              <div className="col-lg-6 col-md-6 col-12 mb-2 mb-sm-0 mb-lg-0">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label">Relative Last Name:</label>
                  </div>
                  <div className="col-8">
                    <p>{insurance && insurance.relation_lastname ? insurance.relation_lastname : 'N/A'}</p>
                  </div>

                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12 mb-3 mb-sm-0 mb-lg-0">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Member ID:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.policy ? insurance.policy : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Group:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.group_number ? insurance.group_number : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Phone:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.insurance_number ? insurance.insurance_number : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12 mb-3 mb-sm-0 mb-lg-0">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Rx Member ID:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.member_id ? insurance.member_id : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">BIN #:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.bin ? insurance.bin : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">PCN #:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.pcn ? insurance.pcn : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Rx Group:</label>
              </div>
              <div className="col-8">
                <p>{insurance && insurance.rx_group ? insurance.rx_group : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowInsuranceInformation;
