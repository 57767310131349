import { FC,useState } from "react"

import Modal from "../modal";
import { StatisticsObjectType } from "./types";

const CustomButtonRight: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {
  const [showingModal, setModal] = useState(false);
  const [title, setTitle] = useState("");




  const openModal = (title: string) => {
    setTitle(title);
    setModal(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <div>

        {showingModal ? <Modal title={title} data={title === "New Practices" ? StatisticsObjectType.new_practices.data : StatisticsObjectType.new_doctors.data} onClick={() => setModal(false)}></Modal> : null}


      <div className="card mb-3">
        <div className="row m-0">
          <div className="card-header col-5 text-center">
            <h4 className="mt-2 mb-0">Cases Total (w/ ECR) </h4>
          </div>
          <div className="card-body col-7 text-center">
            <h1 className="mt-2 mb-0">{StatisticsObjectType.case_total_with_ecr}</h1>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="row m-0">
          <div className="card-header col-5 text-center">
            <h4 className="mt-2 mb-0">New Practices</h4>
          </div>
          <div className="card-body col-7 text-center" onClick={() => {
            openModal("New Practices")
          }}>
            <h1 className="text-primary fs-3 fw-bold link-modal text-center fw-bold">{StatisticsObjectType.new_practices.count}</h1>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="row m-0">
          <div className="card-header col-5 text-center">
            <h4 className="mt-2 mb-0">New Doctors</h4>
          </div>
          <div className="card-body col-7 text-center" onClick={() => {
            openModal("New Doctors")
          }}>
            <h1 className="text-primary fs-3 fw-bold link-modal text-center fw-bold">{StatisticsObjectType.new_doctors.count}</h1>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="row m-0">
          <div className="card-header col-5 text-center">
            <h4 className="mt-2 mb-0">Transfers</h4>
          </div>
          <div className="card-body col-7 text-center">
            <h1 className="mt-2 mb-0">{StatisticsObjectType.transfers === null ? `0 %` : StatisticsObjectType.transfers}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomButtonRight;
