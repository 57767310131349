

import { FC, useEffect, useContext, useState } from "react";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import FooterText from "src/components/footerText";
import { CaseListingType, ProvidersObject, StatisticsApiResponse, statisticsErrorAndData } from "./types";
import CaseVolumeHorizontal from "src/components/manufacturer/caseVolumeHorizontal";
import PieChartTerritories from "src/components/manufacturer/pieChartTerritories";
import Practices from "src/components/manufacturer/practices";
import Provider from "src/components/manufacturer/provider";
import CaseDetail from "src/components/manufacturer/caseDetail";
// import OngoingTreatment from "src/components/manufacturer/ongoingTreatment";
// import CaseFilter from "src/components/manufacturer/statistics";
import { TerritoriesSearchProps } from "src/components/manufacturer/statistics/types";
import TerritoriesFilter from "src/components/manufacturer/territoriesFilter";

const Territories: FC = (): JSX.Element => {

  const { destroySession } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let [searchParams, updateSearchParams] = useSearchParams();

  const [queryParams, changeQueryParams] = useState<TerritoriesSearchProps>({
    start_date:
      searchParams.get("start_date") ||
      format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd"),
    end_date:
      searchParams.get("end_date") ||
      format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
    period: searchParams.get('period') || "",
    filter: searchParams.get("filter") || "",
    // time: searchParams.get("time") || "",
    type: searchParams.get('type') || "",
    role: searchParams.get('role') || "",
    id: Number(searchParams.get('id')),
  });


  const { data: statisticsData, error: errorAndData, mutate: getStatsData } =
    useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS, queryParams, {}, true);

  useEffect(() => {
    hideLoader();
    if (errorAndData) {
      const { error, code } = errorAndData as unknown as statisticsErrorAndData;
      hideLoader();
      showToast([error || `Can't fetch statistics right now. Please try again in a while.`], ToastTypes.ERROR);

      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAndData]);

  useEffect(() => {
    if (statisticsData) {
      hideLoader();
      if (statisticsData.code === 200) {
        showToast([statisticsData.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);

  const updateQueryParamsAndCallAPI = (
    updatedQueries: TerritoriesSearchProps,
    isReset: boolean = false
  ): void => {
    if (isReset) {
      updateSearchParams({});
      changeQueryParams({
        start_date: format(
          new Date().setDate(new Date().getDate() - 30),
          "yyyy-MM-dd"
        ),
        end_date: format(
          new Date().setDate(new Date().getDate()),
          "yyyy-MM-dd"
        ),
        period: "",
        filter: "",
        // time: "",
        type: searchParams.get('type') || "",
        role: searchParams.get('role') || "",
        id: Number(searchParams.get('id')),
      });
    } else {
      changeQueryParams(updatedQueries);
    }

    setLoader();
    getStatsData({}, {}, updatedQueries);
  };


  return (
    <div className="container-fluid auth-page mb-4 overflow-x-hidden">
      <TerritoriesFilter
        defaultQueryParams={queryParams}
        updateQueryParams={updateQueryParamsAndCallAPI}
      />
      <div className="mt-3">
        {statisticsData && <CaseVolumeHorizontal StatisticsObjectType={statisticsData.data} />}
      </div>
      <div className="mb-3">
        {statisticsData && <PieChartTerritories StatisticsObjectType={statisticsData.data} />}
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 mb-3">
          <Practices/>
        </div>
        <div className="col-12 col-sm-12 col-md-8">
          {statisticsData && <Provider providerList={statisticsData.data.providers as unknown as ProvidersObject}/>}
        </div>
      </div>
      <div className="row">
        <div className="mt-3">
          {statisticsData && <CaseDetail baseUrl="/statistics" searchParams={queryParams} CaseDetailListType={statisticsData.data.case_listing as unknown as CaseListingType[]} />}
        </div>
      </div>
      <FooterText footerType="black" />
    </div>

  );
};

export default Territories;
