import { FC, useCallback, useEffect, useState } from "react";
import HighCharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import {
  PIE_CHART_OPTIONS,
} from "src/constants/highCharts";
import { wrapperFunctionPieChart } from "src/helpers";
import { pieChartObject } from "./types";



const CustomPieChart: FC<pieChartObject> = ({ pieChartObject,title}): JSX.Element => {
  const [data, setData] = useState({});

  const populatePieChart = useCallback(() => {
    const topPayorOptions = {
      ...PIE_CHART_OPTIONS,
      series: wrapperFunctionPieChart(pieChartObject)
    };
    setData(topPayorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieChartObject]);

  useEffect(() => {
    populatePieChart();
  }, [populatePieChart]);

  return (
    <div>
      
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="m-0 text-center">{title}</h4>
          </div>
          <div className="card-body">
            <HighchartsReact highcharts={HighCharts} options={data} />
          </div>
        </div>
    </div>
  );
};

export default CustomPieChart;
