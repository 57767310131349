import { FC, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FooterText from "../../components/footerText";
import AuthSubHeader from "src/components/authSubHeader";

import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";

import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { SubmitPrecertResponse } from "./types";
import { ACCEPTED_PRECERT_FILE_EXTENSIONS } from "src/constants";
import { LoaderContext } from "src/context/loaderContext";


const Precert: FC = (): JSX.Element => {
  const [uploadFile, changeUploadFile] = useState<FileList | null>(null);

  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { caseId, precertsId } = useParams();





  const { loading: isFileUploading, error: submitPrecertError, data: submitPrecertData, mutate: submitPrecert } =
    useFetch<SubmitPrecertResponse>(APIEndpoints.SUBMIT_PRECERT, {}, { caseId, precertId: precertsId });

  useEffect(() => {
    if (submitPrecertData || submitPrecertError) {
      hideLoader()
      if (submitPrecertData) {
        const { response } = submitPrecertData;
        showToast([response], ToastTypes.SUCCESS);
      }
      navigate('/cases');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitPrecertData, submitPrecertError]);



  const completeMySelf = () => {
    const formData = new FormData();
    formData.append("precert[test]", "");
    if (uploadFile)
      formData.append("clinical_document", uploadFile[0]);
    formData.append("commit", "cancel");
    setLoader();
    submitPrecert(formData);
  };

  const completeOnMyBehalf = () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("precert[test]", "");
      formData.append("clinical_document", uploadFile[0]);
      formData.append("commit", "submit_clinical_document");
      setLoader();
      submitPrecert(formData);
    }
  };





  const reset = () => {
    if (ref && ref.current) {
      ref.current.value = ""
    }
  };

  const clearDataAndForm = () => {
    changeUploadFile(null);
    reset();
  };

  const validateAndShowToast = (file: File) => {
    const caseFileExt = `.${file.name.split(".").pop()}`;
    const isExtAccepted = ACCEPTED_PRECERT_FILE_EXTENSIONS[caseFileExt];
    if (typeof isExtAccepted === "string") {
      const sizeInMBs = Number((file.size / (1024 * 1024)).toFixed(2));
      if (sizeInMBs <= 25) {
        return true;
      } else {
        showToast(["Document size should not exceed 25mb."], ToastTypes.WARNING);
      }
    } else {
      showToast(
        [`Not an acceptable format. The accepted formats are ${Object.keys(ACCEPTED_PRECERT_FILE_EXTENSIONS).toString()}`],
        ToastTypes.ERROR
      );
    }
    return false;
  };
  
  const handleConfirmAndExecute = (title: string, action: Function) => {
    const result = window.confirm(title);
    if (result) action();
  };
  
  const ConfirmAlert = (title: string, funcName: string) => {
    if (uploadFile) {
      if (validateAndShowToast(uploadFile[0])) {
        handleConfirmAndExecute(title, funcName === "completeMySelf" ? completeMySelf : completeOnMyBehalf);
      }
    } else {
      if (funcName === "completeMySelf") {
        handleConfirmAndExecute(title, completeMySelf);
      } else {
        showToast(["Document should be uploaded before submit."], ToastTypes.WARNING);
      }
    }
  };



  return (
    <div className="container-fluid auth-page">
      <div className="container-fluid row justify-content-center pt-3 pb-4">
        <div className="col-12 col-sm-10 col-md-8">
          <AuthSubHeader />

          <div className="card">
            <div className="card-header">
              <h4 className="text-primary p-2 mb-0">Upload Clinical Information</h4>
            </div>

            <div className="card-body">
              <form>
                <p className="form-text">
                  If you wish for us to complete any precertification or prior authorization which may be required on your
                  behalf, simply upload the patient's chart in the field below. Alternatively you can fax us the clinicals at any
                  time and we'll attach them to the case.
                </p>

                <div className="card">
                  <div className="card-header">
                    <h4 className="fs-6 text p-2 mb-0"><i className="bi bi-cloud-arrow-up-fill"></i>  Upload Patient Chart</h4>
                  </div>

                  <div className="card-body">
                    <div className="input-group">
                      <input type="file" className="form-control" id="fileUpload" onChange={(e) => changeUploadFile(e.target.files)} ref={ref} />
                      {uploadFile ? (
                        <button
                          className="btn btn-outline-danger"
                          onClick={clearDataAndForm}
                          type="button"
                          id="fileUpload"
                          disabled={isFileUploading}
                        >
                          Clear
                        </button>
                      ) : null}
                    </div>

                    <p className="fs-6 pt-1">
                      Click here to upload the patient's chart. Currently we accept .txt, .pdf, .tiff, .doc, .ccd, .cda, .ccr,
                      .ccda and image files.
                    </p>
                  </div>
                </div>

                <div className="text-end mt-3">
                  <button
                    className="btn btn-purple rounded-1"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Skip for Now
                  </button>
                  <button
                    className="btn btn-danger mx-3 rounded-1"
                    type="button"
                    name="cancel"
                    onClick={() => ConfirmAlert("Are you sure you would like to i will complete myself?\n This action can not be undone.", "completeMySelf")}
                    disabled={isFileUploading}
                  >
                    I Will Complete Myself
                  </button>
                  <button
                    className="btn btn-success rounded-1"
                    type="button"
                    onClick={() => ConfirmAlert("Are you sure you would like to complete on my behalf?\n This action can not be undone.","completeOnMyBehalf")}
                    disabled={!uploadFile || isFileUploading}
                  >
                    Complete On My Behalf
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <FooterText footerType="black" />
      </div>
      
    </div >
  );
};

export default Precert;
