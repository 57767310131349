import { FC } from "react";
import { CaseVolumeProps } from "../caseVolume/types";
import CustomPieChart from "../customPieChart";

const Top10Payors: FC<CaseVolumeProps> = ({ StatisticsObjectType }): JSX.Element => {

  return (
    <div className="row">
        <div className="col-12 col-sm-4 col-lg-4">
            <CustomPieChart title={'Top 10 Payors'} pieChartObject={StatisticsObjectType.top_payors} />
        </div>
        <div className="col-12 col-sm-4 col-lg-4">
            <CustomPieChart title={'ECR Breakdown'} pieChartObject={StatisticsObjectType.ecr}/>
        </div>
        <div className="col-12 col-sm-4 col-lg-4">
            <CustomPieChart title={'Cases By Submission Mode'} pieChartObject={StatisticsObjectType.mos}/>
        </div>
    </div>
  );
};

export default Top10Payors;
