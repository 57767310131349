import { AxiosError, isAxiosError } from "axios";
import { ErrorMessages } from "src/constants/api";
import { TryCatchError } from "src/types";

export const handlingAPIError = (error: unknown): { isAuthIssue: boolean; error: string } => {
  if (isAxiosError(error)) {
    const { response } = error as AxiosError<string>;

    if (response && typeof response.data !== "string") {
      const { code, error: errorMessage, errors } = response.data as unknown as { code: number; error: string; errors?: string[] };

      if (code === 401) return { isAuthIssue: true, error: "You need to sign up or sign in to continue" };
      else return { isAuthIssue: false, error: errors && Array.isArray(errors) ? errors[0] : errorMessage };
    } else {
      return { isAuthIssue: false, error: ErrorMessages.NetworkError };
    }
  } else {
    return { error: (error as TryCatchError)?.message || "An error occurred", isAuthIssue: false };
  }
}
