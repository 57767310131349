import { Dispatch, SetStateAction } from "react";
import { CaseType } from "src/types/case";

export type CaseListingType = CaseType & {
  id: number;
  investigated_at: string | null;
  color_idx: 1 | 2 | 3 | 4 | 5;
  status_name: string;
  created_at: string | null;
  active_precert: boolean;
  active_precert_id:number;
  precert_maximum: boolean;
  precert_minimum: boolean;
  cancelled_state: boolean;
  transfer_rx_button: boolean;
  pdp_button: boolean;
  prescription_id: number;
  drug_name: string;
  category: string;
  case_type:string;
  treated_button:boolean;
  sp_status: string;
  patient: {
    id: number;
    external_id: string;
    firstlastname: string;
  };
  doctor: {
    id: number;
    firstlastname: string;
    practice_id: number;
  };
  treatment?: {
    id: number;
    scheduled_at: string | null;
  };

};

export type caseListAPIResponse = {
  code: number;
  message: string;
  data: {
    case: CaseType[];
  };
};

export type CaseListAPIResponseType = {
  status: number;
  cases: CaseListingType[];
  total_pages: number;
  investigated: number;
  pending: number;
};



export type QueryParamsState = {
  open: string | boolean;
  sort_by: string;
  direction: 'asc' | 'desc';
  search_query: string;
  date_from: string;
  date_to: string;
  page: number;
  category_type: string;
};

export enum QueryStateActions {
  CHANGE_CASE_STATUS = 'changeCaseStatus',
  CHANGE_SORT_PARAMS = 'changeSortParams',
  CHANGE_SEARCH_QUERY = 'changeSearchQuery',
  CHANGE_FROM_DATE = 'changeFromDate',
  CHANGE_TO_DATE = 'changeToDate',
  CLEAR_STATE = 'clearState',
  CHANGE_PAGE = 'changePage',
  CHANGE_CATEGORY_TYPE = 'changeCategoryType',
};

export type QueryStateActionType =
  {
    type: QueryStateActions.CHANGE_CASE_STATUS;
    payload: boolean | string;
  } | {
    type: QueryStateActions.CHANGE_CATEGORY_TYPE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_SORT_PARAMS;
    payload: {
      sort_by: string;
      direction: 'asc' | 'desc';
    };
  } | {
    type: QueryStateActions.CHANGE_SEARCH_QUERY;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_FROM_DATE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_TO_DATE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_PAGE;
    payload: number;
  } | {
    type: QueryStateActions.CLEAR_STATE;
  };


export type DownloadZipResponseType = {
  response: string;
};
export  type DownloadPDFResponseTypeApi = {
  code:number;
  message:string;
  response:string;
  report_name: string;

}

export type DownloadZipErrorType = {
  code: number;
  error: string;
};
export type statetype ={
  case_id:number,
  id: number,
  insurance_id?:number, 
}

export type RenderNextStepProps = {
  kase: CaseListingType;
  setDataForCancelModal: Dispatch<SetStateAction<{
    isModalVisible: boolean;
    caseId: number;
    prescriptionId: number;
  }>>;
  onTreatedOrNotTreatedButtonClicked: (value: string, treatment_id: number) => void;
  getCaseListing: {
    isCaseLoading: boolean;
    getListing: <TBodyType, TPathParams, TQueryParams>(
      body?: TBodyType | undefined,
      pathFromData?: TPathParams | undefined,
      queryFromData?: TQueryParams | undefined
    ) => void
  }
};
