import { EMAIL_REGEX } from "src/constants";
import  * as yup from "yup";

const spaceCheckYupValidation = (val?: string) => val ? (!/^\s*$/.test(val)) : false

export const AddCaseSchema = yup.object({
  mos: yup.string().required(),
  submission_source: yup.string().required(),
  user_id: yup.number().required(),
  creator_name: yup.string().required(),
  doctor_firstlastname: yup.string(),
  doctor_fullname: yup.string(),
  patient_firstlastname: yup.string(),
  patient_fullname: yup.string(),
  patient_dob: yup.string(),
  practice_name: yup.string(),
  original_id: yup.number(),
  category: yup.string().required('Case type is required'),

  procedure_attributes: yup.object({
    cpt_code: yup.string().required(`Procedure is required`),
  }).required(`Procedure is required`),

  prescription_attributes: yup.object({
    qty: yup
      .number()
      .typeError("Quantity must be a valid number")
      .required(`Quantity is required`)
      .min(1, 'Quantity for drug cannot be less than 1')
      .max(99, 'Quantity for drug cannot be greater than 99'),
    indication: yup.string(),
    ndc: yup.string(),
    drug_id: yup.number(),
    drug_name: yup.string().required('Product is required'),
    days_supply: yup.string(),
    prescription_attributes_attributes: yup.array().of(
      yup.object({
        name: yup.string(),
        value: yup.string(),
      }),
    ),
    refills_authorized: yup.number(),
    allergies: yup
      .string()
      .max(255, 'Allergies can only contain 255 characters')
      .required('Allergies is required')
      .test("whiteSpaceRemoval", "Allergies should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    diagnosis_code: yup
      .array()
      .of(
        yup.string().required(`Diagnosis code is required`)
      )
      .min(1, `Diagnosis code is required`)
      .required(`Diagnosis code is required`),
    other_medical_conditions: yup
      .string()
      .max(255, 'Medical conditions can only contain 255 characters')
      .required('Medical conditions is required')
      .test("whiteSpaceRemoval", "Medical conditions should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    direction: yup
      .string()
      .max(255, "Direction should only be 255 characters long")
      .when({
        is: (val: string) => val && val.length > 0,
        then: (schema) => 
          schema.test("whiteSpaceRemoval", "Direction should not contain only spaces", (val) => spaceCheckYupValidation(val))
      }),
    note: yup
      .string()
      .max(70, "Note should only be 70 characters long")
      .when({
        is: (val: string) => val && val.length > 0,
        then: (schema) => 
          schema.test("whiteSpaceRemoval", "Note should not contain only spaces", (val) => spaceCheckYupValidation(val))
      }),
    written_date: yup.string(),
    product: yup.string(),
    autoDirectPurchase: yup.boolean(),
    directPurchase: yup.boolean(),
    benefitOnly: yup.boolean(),
  })
  .required(`prescription attributes are required`),

  doctor_attributes: yup.object({
    practice_id: yup.number().required(`Practice is required`),
    global_doctor_id: yup.number().required(`Administrative provider is required`),
  }),

  patient_attributes: yup.object({
    global_patient_id: yup.number().default(0).required(`Patient is required`),
    title: yup.string(),
    firstname: yup.string()
      .required("First name is required")
      .max(35, "First name should only be 35 characters long")
      .test("whiteSpaceRemoval", "First name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    middlename: yup
      .string()
      .max(35, "Middle name should only be 35 characters long")
      .when({
        is: (val: string) => val && val.length > 0,
        then: (schema) => schema
          .test("whiteSpaceRemoval", "Middle name should not contain only spaces", (val) => spaceCheckYupValidation(val))
      }),
    lastname: yup
      .string()
      .required("Last name is required")
      .max(35, "Last name should only be 35 characters long")
      .test("whiteSpaceRemoval", "Last name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    ssn: yup
      .string()
      .when({
        is: (val: string) => val && val.length > 0,
        then: (schema) => 
          schema
            .min(9, 'SSN should be 9 digits long').max(9, 'SSN should be 9 digits long')
            .test("containAllDigits", "SSN should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false),
      }),
    dob: yup
      .string()
      .required("DOB is required")
      .test(
        "dates-test",
        "Date of Birth should be less than present date",
        (value: string) => {
          const dob = new Date().toISOString().substring(0, 10);
          return dob >= value;
        }
      ),
    patient_email_id: yup.number().required().nullable(),
    patient_email: yup
      .string()
      .when({
        is: (val: string) => val && val.length > 0,
        then: (schema) => schema
          .max(70, "Email should only be 70 characters long")
          .test("isEmailValid", "Email should be a valid email", (val?: string) => val && EMAIL_REGEX.test(val) ? true : false)
      }),
    gender: yup.string().required("Gender is required"),
    best_time_to_contact: yup.string(),
    phone_numbers: yup.array().of(
      yup.object({
        phone_type: yup.string().required("Phone type is required"),
        number: yup
          .string()
          .required(`Phone number is required`)
          .min(10, `Phone number invalid`)
          .max(10, `Phone number invalid`)
          .test('range', 'Phone number invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        is_preferred: yup.boolean().default(false).required("Preferred phone is required"),
        is_ok_to_leave_message: yup.boolean().default(false).required("OK to leave message is required"),
        id: yup.number().required().nullable(),
      })
    )
    .min(1, "At least one phone number is required for patient")
    .required(`at least one phone number is required for patient`),

    address: yup.object({
      street: yup
        .string()
        .required(`Street is required`)
        .test("whiteSpaceRemoval", "Street should not contain only spaces", (val) => spaceCheckYupValidation(val)),
      suite: yup
        .string()
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => 
            schema.test("whiteSpaceRemoval", "Suite should not contain only spaces", (val) => val ? spaceCheckYupValidation(val) : true)
        }),
      city: yup
        .string()
        .required(`City is required`)
        .test("whiteSpaceRemoval", "City should not contain only spaces", (val) => spaceCheckYupValidation(val)),
      state: yup.string().required(`State is required`),
      zip: yup
        .string()
        .required(`Zip is required`)
        .max(9, "Zip should not be more than 9 digits")
        .test("containAllDigits", "Zip should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false),
      address_name: yup.string().required(`Address type is required`),
    }).required(`Address for patient information is required`),
  }).required(`Patient details are required`),

  insurances_attributes: yup.array().of(
    yup.object({
      insurance_name: yup
        .string()
        .required(`Insurance Name is required`)
        .test("whiteSpaceRemoval", "Insurance Name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
      relation_to_patient: yup
        .string()
        .required(`Relation to patient is required`),
      relation_firstname: yup
        .string()
        .max(35, "First name should only be 35 characters long")
        .when("relation_to_patient", {
          is: (relation: string) => relation && relation !== "self",
          then: (schema) => schema.required("First name is required"),
        })
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => schema
            .test("whiteSpaceRemoval", "First name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
        }),
      relation_middlename: yup
        .string()
        .max(35, "Middle name should only be 35 characters long")
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => schema
            .test("whiteSpaceRemoval", "Middle name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
        }),
      relation_lastname: yup
        .string()
        .max(35, "Last name should only be 35 characters long")
        .when("relation_to_patient", {
          is: (relation: string) => relation && relation !== "self",
          then: (schema) => schema.required("Last name is required"),
        })
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => schema
            .test("whiteSpaceRemoval", "Last name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
        }),

      policy: yup
        .string()
        .required(`Member id is required`)
        .test("whiteSpaceRemoval", "Member id should not contain only spaces", (val) => spaceCheckYupValidation(val)),
      group_number: yup
        .string()
        .max(45, "Group should only be 45 characters long")
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => schema
            .test("whiteSpaceRemoval", "Group should not contain only spaces", (val) => spaceCheckYupValidation(val)),
        }),
      insurance_number: yup
        .string()
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => schema
            .min(10, `Insurance phone number invalid`)
            .max(10, `Insurance phone number invalid`)
            .test('range', 'Insurance phone number invalid', (val) => {
              const numberedVal = Number(val)
              if (!isNaN(numberedVal)) {
                if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
              }
              return false;
            })
        }),

      member_id: yup
        .string()
        .max(55, "Rx member id should only be 55 characters long")
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => 
            schema.test("whiteSpaceRemoval", "Rx member id should not contain only spaces", (val) => spaceCheckYupValidation(val))
        }),
      bin: yup
        .string()
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => schema
            .max(6, 'bin should be 6 digits long')
            .test("containAllDigits", "bin should only contain digits", (val) => spaceCheckYupValidation(val)),
        }),
      pcn: yup
        .string()
        .max(45, "PCN should only be 45 characters long")
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => 
            schema.test("whiteSpaceRemoval", "PCN should not contain only spaces", (val) => spaceCheckYupValidation(val))
        }),
      rx_group: yup
        .string()
        .max(45, "Rx group should only be 45 characters long")
        .when({
          is: (val: string) => val && val.length > 0,
          then: (schema) => 
            schema.test("whiteSpaceRemoval", "Rx group should not contain only spaces", (val) => spaceCheckYupValidation(val))
        }),

      is_primary_insurance: yup.boolean().required(),
      insurance_priority: yup.number().required(),
      global_insurance_id: yup.string().required().nullable(),
    }),
  )
  .when("prescription_attributes", {
    is: (prescription_attributes: { directPurchase: boolean }[]) => prescription_attributes && prescription_attributes.length > 0 && prescription_attributes[0].directPurchase === false,
    then: (schema) => schema.required("Patient insurance information is required"),
  }),
});
