import { FC } from "react"

import RBDs from "../RBDs";
import KAMs from "../KAMs";
// import FAMs from "../FAMs";

import { KAMsRBDsFAMsPropsType, KAMsListObjectType, RBDsListObjectType } from "./types";




const TBMsRBDsFRMs: FC<KAMsRBDsFAMsPropsType> = ({ KAMsListType, FAMsListType, RBDsListType }): JSX.Element => {
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 mt-3 overflow-auto">
        <KAMs data={KAMsListType?.data  as unknown as KAMsListObjectType[]} ecr_total = {KAMsListType?.ecr_total} case_total={KAMsListType?.case_total}/>
      </div>
      {/* <div className="col-12 col-sm-6 col-md-6 mt-3 overflow-auto">
        <RBDs data={RBDsListType?.data as unknown as RBDsListObjectType[]} ecr_total  = { RBDsListType?.ecr_total} case_total={RBDsListType?.case_total}/>
      </div> */}
      {/* <div className="col-12 col-sm-4 col-md-4 mt-3 overflow-auto">
        <FAMs data={FAMsListType?.data as unknown as FAMsListObjectType[]} ecr_total = {FAMsListType?.ecr_total } case_total={FAMsListType?.case_total}/>
      </div> */}
    </div>
  );
};

export default TBMsRBDsFRMs;
