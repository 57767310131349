import { FC} from "react"
import CustomButtonTop from "../customButtonTop";
import CustomLineChart from "../customLineChart";
import { StatisticsObjectType } from "../caseVolumeHorizontal/types";


const CaseVolume: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {

  return (
    <div className="mt-3">
        <CustomButtonTop StatisticsObjectType={StatisticsObjectType}/>
        <CustomLineChart title="Case Volume" LineChartObject={StatisticsObjectType.case_volume}/>
    </div>
  )
}
export default CaseVolume;
