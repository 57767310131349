import {
  GeographicalStatisticsObject,
  topPayorsMOSECRProps,
} from "src/pages/manufacturer/types";
import packageInfo from "../../package.json";
import axios from "axios";

export const objectToQueryString = (obj: any) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join("&");
  // more code goes here
};

export const wrapperFunction = (value: GeographicalStatisticsObject[]) => {
  const dataArray: Array<[string, number]> = [];

  // Map of specific codes for U.S. territories
  const territoryMap: { [key: string]: string[] } = {
    "us-pr": ['pr-3614'],
    "us-gu": ['gu-3605'],
    "us-as": ['as-6515', 'as-6514'],
    "us-mp": ['mp-ti', 'mp-sa', 'mp-ro'],
    "us-vi": ['vi-3617', 'vi-6398', 'vi-6399']
  };

  if (value && value.length > 0) {
    value.forEach((item) => {
      const stateCode = item.state.toLocaleLowerCase();
      const prefixedCode = "us-" + stateCode;

      // Check if the state is in the territoryMap
      if (territoryMap[prefixedCode]) {
        // Add all associated codes for the territory
        territoryMap[prefixedCode].forEach(code => {
          dataArray.push([code, item.case_count]);
        });
      } else {
        // For regular states
        dataArray.push([prefixedCode, item.case_count]);
      }
    });
  }

  return dataArray;
};

export const wrapperFunctionPieChart = (pieChartData: topPayorsMOSECRProps[]) => {
  return [{
    name: "Percentage",
    type: "pie",
    data: pieChartData.map((item) => ({
      name: `${(item.name || "").toLowerCase().replace(/(^\w|[-\s]\w)/g, (match) => match.toUpperCase())} <b>(${item.value})</b>`,
      y: item.percentage,
    }))
  }]
}

export const updateApiVersion = async () => {
  const response = await axios(
    `${process.env.REACT_APP_BASE_URL}/update_api_version?APP_VERSION=${packageInfo.version}`,
    {
      method: "GET",
      headers: {
        ClientDb: process.env.REACT_APP_CLIENT_DB,
      },
    }
  );
  console.log(response, "     ");
};

export const emptyCacheAndCookies = () => {

  document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Makes sure the page reloads. Changes are only visible after you refresh.
   setTimeout(() => window.location.reload(), 2000)
  }
};
