import { FC, useState } from "react"

import { StatisticsObjectType } from "./types";
import Modal from "../modal";


const CustomButtonTop: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {
  const [showingModal, setModal] = useState(false);
  const [title, setTitle] = useState("");


  const openModal = (title: string) => {
    setTitle(title);
    setModal(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <div className="mb-3">
      {showingModal ? <Modal title={title} data={title === "New Practices" ? StatisticsObjectType.new_practices.data : StatisticsObjectType.new_doctors.data} onClick={() => setModal(false)} /> : null}
      <div className="row g-3">
        <div className="col-12 col-sm-6 col-md-3">
          <div className="card d-flex flex-row overflow-hidden">
            <div className="card-header col-6 d-flex justify-content-center align-items-center border-0 rounded-0">
              <h4 className="m-0">Received Cases</h4>
            </div>
            <div className="card-body col-6 d-flex justify-content-center align-items-center">
              <h1 className="m-0">{StatisticsObjectType.received_cases}</h1>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3">
          <div className="card d-flex flex-row overflow-hidden">
            <div className="card-header col-6 d-flex justify-content-center align-items-center border-0 rounded-0">
              <h4 className="m-0">New Practices</h4>
            </div>
            <div className="card-body col-6 d-flex justify-content-center align-items-center">
              <p className="text-primary link-modal m-0" onClick={() => {
                openModal("New Practices")
              }}>
                <h1 className="m-0">{StatisticsObjectType.new_practices.count}</h1>
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3">
          <div className="card d-flex flex-row overflow-hidden">
            <div className="card-header col-6 d-flex justify-content-center align-items-center border-0 rounded-0">
              <h4 className="m-0">New Doctors</h4>
            </div>
            <div className="card-body col-6 d-flex justify-content-center align-items-center">
              <p className="text-primary link-modal m-0" onClick={() => {
                openModal("New Doctors")
              }}>
                <h1 className="m-0">{StatisticsObjectType.new_doctors.count}</h1>
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3">
          <div className="card d-flex flex-row overflow-hidden">
            <div className="card-header col-6 d-flex justify-content-center align-items-center border-0 rounded-0">
              <h4 className="m-0">Transfers</h4>
            </div>
            <div className="card-body col-6 d-flex justify-content-center align-items-center">
              <h1 className="m-0">
                {StatisticsObjectType.transfers === null ? `0 %` : StatisticsObjectType.transfers + `%`}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomButtonTop;
