import { FC, useEffect, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";

import { GeographicalStatisticsObject, StatisticsApiResponse } from "./types";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";

import FooterText from "src/components/footerText";
import CaseVolume from "src/components/manufacturer/caseVolume";
import GeographicalStatistics from "src/components/manufacturer/geographicalStatistics";
import CaseFilter from "src/components/manufacturer/statistics";
import { StatisticsSearchProps } from "src/components/manufacturer/statistics/types";
import Top10Payors from "src/components/manufacturer/top10Payors";
import KAMsRBDsFAMs from "src/components/manufacturer/TBMsRBDsFRMs";
import {
  FAMsList,
  KAMsList,
  RBDsList,
} from "src/components/manufacturer/TBMsRBDsFRMs/types";
import CustomPieChart from "src/components/manufacturer/customPieChart";




const Manufacturer: FC = (): JSX.Element => {
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const [searchParams, updateSearchParams] = useSearchParams();

  const [queryParams, changeQueryParams] = useState({
    start_date:
      searchParams.get("start_date") ||
      format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd"),
    end_date:
      searchParams.get("end_date") ||
      format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
    period: searchParams.get('period') || "",
    filter: searchParams.get("filter") || "",
    // time: searchParams.get("time") || "",
    type: "index",
  });

  const { data: statisticsData, mutate: getStatsData } = 
    useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS, queryParams);

  useEffect(() => {
    if (statisticsData) {
      hideLoader();
      if (statisticsData.code === 200) {
        showToast([statisticsData.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);



  const updateQueryParamsAndCallAPI = (
    updatedQueries: StatisticsSearchProps,
    isReset: boolean = false
  ): void => {
    if (isReset) {
      updateSearchParams({});
      changeQueryParams({
        start_date: format(
          new Date().setDate(new Date().getDate() - 30),
          "yyyy-MM-dd"
        ),
        end_date: format(
          new Date().setDate(new Date().getDate()),
          "yyyy-MM-dd"
        ),
        period: "",
        filter: "",
        // time: "",
        type: "",
      });
    } else {
      changeQueryParams(updatedQueries);
    }

    setLoader();
    getStatsData({}, {}, updatedQueries);
  };

  return (
    <div className="container-fluid overflow-auto">
      <CaseFilter
        defaultQueryParams={queryParams}
        updateQueryParams={updateQueryParamsAndCallAPI}
      />
      {statisticsData && statisticsData.data && (
        <CaseVolume StatisticsObjectType={statisticsData.data} />
      )}
      {statisticsData && statisticsData.data && (
        <Top10Payors StatisticsObjectType={statisticsData.data} />
      )}
      {statisticsData && statisticsData.data && (
        <CustomPieChart title={'Top 10 Diagnoses'} pieChartObject={statisticsData.data.diagnosis}/>
      )}
      {statisticsData && statisticsData.data && (
        <KAMsRBDsFAMs
          KAMsListType={statisticsData?.data.kams as unknown as KAMsList}
          FAMsListType={statisticsData?.data.fams as unknown as FAMsList}
          RBDsListType={statisticsData?.data.rbds as unknown as RBDsList}
        />
      )}
      {statisticsData && statisticsData.data && (
        <GeographicalStatistics
          GeographicalStatisticsListType={
            statisticsData.data
              .geographical_stats as unknown as GeographicalStatisticsObject[]
          }
        />
      )}
      <FooterText footerType="black" />
    </div>
  );
};

export default Manufacturer;
