
import { FC } from "react";
import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import { arrayConversion } from "./const";
const mapData = require("@highcharts/map-collection/countries/us/custom/us-all-territories.topo.json");



const HighchartsMapComponent: FC<HighchartsReact.Props> = (props: HighchartsReact.Props): JSX.Element => {

  const options: Highcharts.Options = {
    chart: {
      map: mapData,
    },
    colorAxis: {
      min: 60
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: ' years'
    },
    series: [{
      type: 'map',
      mapData: mapData,
      data: arrayConversion(),
      dataLabels: {
        enabled: true,
        color: '#00000',
        format: '{point.code}'
      },
      name: 'Case Volume',
      tooltip: { pointFormat: 'Cases: {point.value}' },
      animation: true,
    }]
  }

  return (
    <div id='mapChart'>
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        constructorType={'mapChart'}
        {...props}
      />
    </div>
  );
}

export default HighchartsMapComponent;
