import { saveAs } from "file-saver";
import { format } from "date-fns";
import ExcelJS from "exceljs";

import { ACCEPTED_FILE_EXTENSIONS_CASE } from "src/constants";
import { NewDoctorType, NewPracticeType } from "src/components/manufacturer/modal/types";

export const downloadZipFile = (base64Data: string, fileName: string) => {
  fetch(`data:applicatiaon/x-zip-compressed;base64,${base64Data}`)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    });
};

export const downloadPdfFile = (base64Data: string, fileName: string) => {
  fetch(`data:applicatiaon/pdf;base64,${base64Data}`)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    });
};
export const downloadExcelFile = (base64Data: string, fileName: string) => {
  fetch(`data:applicatiaon/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    });
};

export const downloadFileAccordingToExtension = (base64Data: string, fileName: string) => {
  const fileExtension = `.${fileName.split(".").pop()}`;
  if (fileExtension === ".xlsx") downloadExcelFile(base64Data, fileName);
  else
    fetch(`data:${ACCEPTED_FILE_EXTENSIONS_CASE[fileExtension]};base64,${base64Data}`)
      .then(res => res.blob())
      .then((blob) => {
        saveAs(blob, fileName);
      });
};

export const createAndDownloadExcelFile = async (data: NewDoctorType[] | NewPracticeType[], isPractice: boolean) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  
  // Add a new worksheet
  const worksheet = workbook.addWorksheet('Sheet 1');

  // Add headers to the worksheet
  let headerRow;
  if (isPractice)
    headerRow = worksheet.addRow(['Name',	'Street',	'Suite', 'City', 'State', 'Zip', 'Phone', 'Fax', 'NPI', 'Tax ID', 'Created Date']);
  else
    headerRow = worksheet.addRow(['Name', 'Speciality', 'Practice',	'Street',	'Suite', 'City', 'State',	'Zip', 'Phone',	'Fax', 'NPI', 'Tax ID', 'Created Date']);

  // Optional: Set column widths for better visibility
  worksheet.columns.forEach(column => {
    column.alignment = { wrapText: true, horizontal: "left", vertical: "middle" } as Partial<ExcelJS.Alignment>;
    column.width = 20;
    column.font = { size: 12 };
  });

  // Apply styles: bold and large font size (e.g., 16)
  headerRow.font = {
    bold: true,
    size: 14,
  };

  data.forEach((item) => {
    if (isPractice) {
      const date = new Date(item.created_at);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      worksheet.addRow([item.name || "", item.street || "", item.suite || "", item.city || "", item.state || "", item.zip || "", item.phone || "", item.fax || "", item.npi || "", item.tax_id || "", format(utcDate, "MM/dd/yyyy")]);
    }
    else {
      const date = new Date(item.created_at);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      worksheet.addRow([item.name || "", (item as NewDoctorType).specialty || "", item.practice || "", item.street || "", item.suite || "", item.city || "", item.state || "", item.zip || "", item.phone || "", item.fax || "", item.npi || "", item.tax_id || "", format(utcDate, "MM/dd/yyyy")]);
    }
  });

  // Write the workbook to an xlsx file
  workbook.xlsx.writeBuffer()
    .then(buffer => saveAs(new Blob([buffer]), `${isPractice ? 'Harmoknee_PracticeListing' : 'Harmoknee_DoctorListing'}.xlsx`))
};
