import { FieldPath, FieldValues } from "react-hook-form";
import Select, { MultiValue, PropsValue, SingleValue } from "react-select";

import { HubInputProps } from "./types";

const HubInputField =
  <T extends FieldValues, K extends FieldPath<T>>({
    isRowBased,
    isRowInsideAnother,
    inputLabel,
    inputId,
    isRequired,
    inputPlaceholder,
    inputType,
    field,
    error,
    onKeyDown,
    onBlur,
    isDropdown,
    isSearchable,
    dropdownData,
    // defaultValue,
    isMulti,
    getSearchPhraseFromDropdown,
  }: HubInputProps<T, K>): JSX.Element =>
{

  const getValue = (value: string | string[]) => {
    if (dropdownData) {
      if (value === "") return null;

      const data = dropdownData.find((option) => {
        if (Array.isArray(value)) {
          return value.indexOf((option as unknown as { value: string; label: string; }).value) > -1;
        } else {
          return (option as unknown as { value: string; label: string; }).value === value;
        }
      });

      if (data) return data as unknown as PropsValue<string>;
    }
  };

  const onChangeSelect = (value: SingleValue<string | { label: string; value: string; }> | MultiValue<string | { label: string; value: string; }>) => {
    if (Array.isArray(value))
      field.onChange(
        value.map((val) => (val as unknown as { value: string; label: string; }).value)
      );
    else
      field.onChange((value as unknown as { value: string; label: string; }).value);
  };

  const RowBasedInput = (
    <div className="row mb-3">
      {inputLabel ? (
        <label htmlFor={inputId} className="text-end form-label col-lg-2 col-md-4 col-12">{inputLabel} {isRequired ? '*' : null}</label>
      ) : null}
      <div className={inputLabel ? "col-lg-10 col-md-8 col-12" : "col-12"}>
        {isDropdown ? (
          <Select
            options={dropdownData}
            id={inputId}
            placeholder={inputPlaceholder}
            isSearchable={isSearchable}
            isMulti={isMulti}
            {...field}
            noOptionsMessage={() => "Select Option..."}
            value={getValue(field.value)}
            onChange={(value) => onChangeSelect(value)}
            onInputChange={getSearchPhraseFromDropdown}
          />
        ) : (
          <input type={inputType ? inputType : "text"} className={`form-control ${error && error.message ? 'is-invalid' : ''}`} id={inputId} placeholder={inputPlaceholder} onKeyDown={onKeyDown} {...field} onBlur={onBlur} />
        )}
        {error && error.message ? (
          <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2 "></div>
          ) : null}
      </div>
    </div>
  );

  const RowInsideRowBasedInput = (
    <div className="row mb-3">
      {inputLabel ? (
        <label htmlFor={inputId} className="text-end form-label col-lg-4 col-md-4 col-12">
            {inputLabel} {isRequired ? '*' : null}
        </label>
      ) : null}
      <div className={inputLabel ? 'col-lg-8 col-md-8 col-12' : 'col-12'}>
        {isDropdown ? (
          <Select
            options={dropdownData}
            id={inputId}
            classNames={{
              control(_props) {
                return `input-group ${error && error.message ? "border-danger" : ""}`
              },
            }}
            placeholder={inputPlaceholder}
            isSearchable={isSearchable}
            isMulti={isMulti}
            {...field}
            noOptionsMessage={() => "Select Option..."}
            value={getValue(field.value)}
            onChange={(value) => onChangeSelect(value)}
            onInputChange={getSearchPhraseFromDropdown}
          />
        ) : (
          <input type={inputType ? inputType : "text"} className={`form-control ${error && error.message ? 'is-invalid' : ''}`} id={inputId} placeholder={inputPlaceholder} onKeyDown={onKeyDown} {...field} onBlur={onBlur} />
        )}
        {error && error.message ? (
          <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2 "></div>
        ) : null}
      </div>
    </div>
  );

  const SimpleInput = (
    <div className="mb-3">
      {inputLabel ? (
          <label htmlFor={inputId} className="form-label">{inputLabel} {isRequired ? '*' : null}</label>
      ) : null}
      {isDropdown ? (
        <Select
          options={dropdownData}
          id={inputId}
          placeholder={inputPlaceholder}
          isSearchable={isSearchable}
          isMulti={isMulti}
          {...field}
          noOptionsMessage={() => "Select Option..."} 
          value={getValue(field.value)}
          onChange={(value) => onChangeSelect(value)}
          onInputChange={getSearchPhraseFromDropdown}
        />
      ) : (
        <input type={inputType ? inputType : "text"} className={`form-control ${error && error.message ? 'is-invalid' : ''}`} placeholder={inputPlaceholder} onKeyDown={onKeyDown} id={inputId} {...field} onBlur={onBlur} />
      )}
      {error && error.message ? (
          <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2 "></div>
      ) : null}
    </div>
  );

  return isRowBased ? (isRowInsideAnother ? RowInsideRowBasedInput : RowBasedInput) : SimpleInput;
};

export default HubInputField;
